import { Button, Card, CardActions, Grid, Typography } from "@mui/joy";
import { Outlet, useNavigate } from "react-router-dom";

export default function DashSettings() {
  const navigate = useNavigate();

  const SettingsComponent = () => {
    return (
      <>
        <Typography marginY={5} level="h3" textAlign={"center"}>
          Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <Card>
              <Typography gutterBottom level="h4">
                Payouts
              </Typography>
              <Typography>
                View your historical and upcoming payouts.
              </Typography>
              <CardActions>
                <Button onClick={() => navigate("/dash/settings/payouts")}>
                  View
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={4}>
            <Card>
              <Typography gutterBottom level="h4">
                Support Contact
              </Typography>

              <Typography>
                Set up your support contact information here. These details will
                appear anywhere where we send the customer details, such as
                emails.
              </Typography>
              <CardActions>
                <Button
                  onClick={() => navigate("/dash/settings/support-contact")}
                >
                  Change
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid xs={4}>
            <Card>
              <Typography gutterBottom level="h4">
                Calendars
              </Typography>

              <Typography>
                Choose a calendar below to view instructions on how to subscribe
              </Typography>
              <CardActions>
                <Button
                  onClick={() => navigate("/dash/settings/support-contact")}
                >
                  Account Listings
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {location.pathname === "/dash/settings" ? (
        <SettingsComponent />
      ) : (
        <Outlet />
      )}
    </>
  );
}
