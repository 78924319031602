/* eslint-disable no-unused-vars */
import {
  Cancel,
  Check,
  FiberManualRecord,
  Info,
  QuestionMark,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  Typography,
} from "@mui/joy";
import { FormGroup } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  useCreateAvailabilityMutation,
  useDeleteAvailabilityMutation,
  useFindAvailabilityByQuery,
  useGetRoomsQuery,
  useUpdateInventoryMutation,
} from "services/osare";
import {
  triggerSuccessSnackbar,
  triggerWarningSnackbar,
} from "services/snackbars";
import SleepingArrangmentsComponent from "./SleepingArrangements";

export default function AvailabilityBuilder(props) {
  console.log("AvailabilityBuilder", props);
  // const randQueryForRooms = _.random(1, 1000);
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const [newFromAvailability, setNewFromAvailability] = useState(
    moment().add(1, "days")
  );
  const [newToAvailability, setNewToAvailability] = useState(
    moment().add(3, "days")
  );
  const {
    data: availabilityData,
    isLoading: isAvailabilityDataLoading,
    isFetching: isAvailabilityDataFetching,
    refetch: refetchAvailability,
  } = useFindAvailabilityByQuery(
    {
      associatedListingId: props.listingId,
      associatedAccommodationId: props.accommodationIds[0],
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: roomsData,
    isLoading: roomsLoading,
    isFetching: roomsFetching,
    refetch: refetchRooms,
  } = useGetRoomsQuery(
    {
      query: {
        accommodationId: props.accommodationIds[0],
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [addAvailability, addAvailabilityResponse] =
    useCreateAvailabilityMutation();

  watch("accommodationIds");
  watch("eventIds");

  useEffect(() => {
    if (addAvailabilityResponse.isSuccess) {
      console.log("addAvailabilityResponse", addAvailabilityResponse.data);
      refetchAvailability();
      refetchRooms();
    }

    if (addAvailabilityResponse.isError) {
      console.log("addAvailabilityResponse", addAvailabilityResponse.error);
      alert(
        `There was an error adding this availability range: \n\n ${addAvailabilityResponse.error.data.errors.join(
          ", "
        )}\n\nPlease check and try again.`
      );
    }
  }, [addAvailabilityResponse.isSuccess, addAvailabilityResponse.isError]);

  const addAvailabilityItem = () => {
    /**
     * First, check that the dates are not in the past
     */

    if (
      +newFromAvailability <= Date.now() ||
      +newToAvailability <= Date.now()
    ) {
      alert('Please check that the "from" and "to" dates are not in the past');
      return;
    }

    if (+newFromAvailability >= +newToAvailability) {
      alert('Please check that the "from" date is before the "to" date.');
      return;
    }

    /**
     * @TODO this check also needs to be added to the backend
     */

    /**
     * Add this availability range to this listing in the backend
     */

    console.log(props);

    addAvailability({
      fromTimestamp: +newFromAvailability,
      toTimestamp: +newToAvailability,
      associatedListingId: props.listingId,
      associatedAccommodationId: props.accommodationIds[0],
      associatedEventId: props.eventIds[0],
    });

    setNewFromAvailability(newToAvailability);
    setNewToAvailability(moment(newToAvailability).add(5, "days"));
  };

  const InventoryItemComponent = (inventoryData) => {
    const [updateInventoryItem, updateInventoryItemResult] =
      useUpdateInventoryMutation();

    const [quantity, setQuantity] = useState(inventoryData.quantity);
    const [price, setPrice] = useState(inventoryData.price);
    const [isShareable, setIsShareable] = useState(inventoryData.isShareable);
    const [edited, setEdited] = useState(false);

    /**
     * Match this inventory item to the room
     */
    const matchedRoomData = roomsData?.results.find(
      (r) => r._id === inventoryData.associatedRoomId
    );

    console.log("matchedRoomData:", matchedRoomData);

    /**
     * Use effect for updating the inventory item
     */
    useEffect(() => {
      if (updateInventoryItemResult.isSuccess) {
        console.log(
          "updateInventoryItemResult.data",
          updateInventoryItemResult.data
        );
        setEdited(false);
        // refetchAvailability();
        setValue("inventoryUpdatedAt", Date.now());
        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );
      }

      if (updateInventoryItemResult.error) {
        console.error(
          "updateInventoryItemResult.error",
          updateInventoryItemResult.error
        );

        alert(
          `Sorry, there was an error updating your inventory item: \n\n${updateInventoryItemResult.error.data.errors.join(
            ", and"
          )}\n\nPlease check and try again.`
        );
      }
    }, [updateInventoryItemResult.isSuccess, updateInventoryItemResult.error]);

    /**
     * Save this inventory item
     */
    const saveInventoryItem = () => {
      console.log("saving inventory item...");

      dispatch(
        triggerWarningSnackbar({
          open: true,
          message: "Updating...",
        })
      );

      updateInventoryItem({
        id: inventoryData._id,
        updateData: {
          quantity,
          price,
          isShareable: isShareable || false,
        },
      });
    };

    return (
      <Card
        color="primary"
        variant={inventoryData.isFinished ? "outlined" : "soft"}
        sx={{ mb: 0 }}
      >
        <FormGroup xs={12}>
          {inventoryData.isFinished ? (
            <Typography
              mb={1}
              color="success"
              fontWeight={"bold"}
              startDecorator={<Check />}
            >
              Finsihed
            </Typography>
          ) : (
            <Typography
              mb={1}
              color="danger"
              fontWeight={"bold"}
              startDecorator={<FiberManualRecord />}
            >
              LIVE
            </Typography>
          )}
          <Typography gutterBottom level="title-lg">
            {matchedRoomData?.name}
          </Typography>
          {matchedRoomData ? (
            <SleepingArrangmentsComponent {...matchedRoomData} />
          ) : (
            <Typography>Waiting for room data...</Typography>
          )}
          {inventoryData.isActive && (
            <Alert sx={{ mb: 0, mt: 2 }} color="success">
              <div>
                <Typography startDecorator={<Info />} marginBottom={1}>
                  This inventory item is actively being purchased from{" "}
                </Typography>
                <Typography fontWeight={"normal"}>
                  You cannot reduce the quantity to lower than the amount
                  purchased already, or change the shareable status. You can
                  still change the price or raise the quantity.
                </Typography>
              </div>
            </Alert>
          )}
        </FormGroup>

        <FormGroup sx={{ py: 0 }} xs={12}>
          <Typography>Is this room sharable?</Typography>
          <Typography gutterBottom level="body-sm">
            This means that this room can be shared by multiple people. You will
            need to arrange the sleeping arrangements for this room.
          </Typography>
          <Checkbox
            readOnly={inventoryData.isFinished}
            disabled={inventoryData.isActive}
            checked={isShareable}
            label="Yes, this room can be shared"
            onChange={(e) => {
              setIsShareable(e.target.checked);
              setEdited(true);
            }}
          />
        </FormGroup>

        <FormGroup xs={12}>
          <Typography gutterBottom>
            How many {isShareable ? "places" : "rooms"} are availabe to book?
          </Typography>
          <Input
            type="number"
            label="Quantity"
            readOnly={inventoryData.isFinished}
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
              setEdited(true);
            }}
            slotProps={{
              input: {
                min: 0,
              },
            }}
          />
          {inventoryData.isActive && (
            <Chip sx={{ mt: 1 }} color="success">
              {inventoryData.quantityPurchased} have already been purchased
            </Chip>
          )}
        </FormGroup>
        <FormGroup>
          <Typography gutterBottom>
            Cost per {isShareable ? "person" : "room"}
          </Typography>
          <Input
            type="number"
            label="Price"
            readOnly={inventoryData.isFinished}
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              setEdited(true);
            }}
            slotProps={{
              input: {
                min: 0,
              },
            }}
          />
        </FormGroup>

        {!inventoryData.isFinished && (
          <Button
            onClick={() => saveInventoryItem()}
            disabled={!edited}
            fullWidth
            variant="soft"
            color="success"
          >
            Save
          </Button>
        )}
      </Card>
    );
  };

  const AvailabilityRangeComponent = (availabilityProps) => {
    const momentFrom = moment(availabilityProps.fromTimestamp);
    const momentTo = moment(availabilityProps.toTimestamp);
    const isFinished = moment().diff(momentTo, "seconds") >= 0;
    const [removeAvailabilityItem, removeAvailabilityItemResult] =
      useDeleteAvailabilityMutation();

    useEffect(() => {
      if (removeAvailabilityItemResult.isError) {
        alert(
          `Sorry, there was problem whilst trying to remove this availability range:\n\n${removeAvailabilityItemResult.error.data.errors.join(
            ", and"
          )}\n\nPlease check and try again.`
        );
      }
    }, [removeAvailabilityItemResult.isError]);

    useEffect(() => {
      if (removeAvailabilityItemResult.isSuccess) {
        console.log(
          "removeAvailabilityItemResult",
          removeAvailabilityItemResult
        );
        refetchAvailability();
      }
    }, [removeAvailabilityItemResult.isSuccess]);

    const removeAvailabilityAction = (idx) => {
      console.log("removeAvailabilityItem", idx);
      if (
        confirm(
          "Please save any other changes you have made to other availability ranges before removing this availability range - as this page will be reloaded and any changes you've made without saving will be lost. Do you want to continue?"
        )
      ) {
        removeAvailabilityItem({ id: idx._id });
      }
    };

    return (
      <Card variant="outlined" sx={{ mb: 2 }}>
        {availabilityProps.toTimestamp < Date.now() ? (
          <Typography>
            {momentFrom.format("dddd, MMMM Do YYYY")} {"  "}❯{"  "}
            {momentTo.format("dddd, MMMM Do YYYY")}
          </Typography>
        ) : (
          <Typography
            startDecorator={
              <IconButton
                disabled={removeAvailabilityItemResult.isLoading}
                onClick={() => removeAvailabilityAction(availabilityProps)}
                color="danger"
              >
                {!removeAvailabilityItemResult.isLoading ? (
                  <Cancel />
                ) : (
                  <CircularProgress />
                )}
              </IconButton>
            }
          >
            {momentFrom.format("dddd, MMMM Do YYYY")} {"  "}❯{"  "}
            {momentTo.format("dddd, MMMM Do YYYY")}
          </Typography>
        )}

        {availabilityProps.inventory?.length > 0 ? (
          availabilityProps.inventory.map((inventoryData, idx) => (
            <InventoryItemComponent
              key={`inventory-${idx}`}
              isFinished={isFinished}
              {...inventoryData}
            />
          ))
        ) : (
          <Alert startDecorator={<QuestionMark />}>
            No rooms found for this accommodation or the date range for this
            availability may be in the past.
          </Alert>
        )}
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography>
              Add a new availability range for this listing
            </Typography>
          </Grid>

          <Grid xs={5}>
            <Input
              type="date"
              onChange={(e) => {
                console.log(e.target.value);
                setNewFromAvailability(moment(e.target.value, "YYYY-MM-DD"));
              }}
              value={moment(newFromAvailability).format("YYYY-MM-DD")}
              slotProps={{
                input: {
                  min: moment().format("YYYY-MM-DD"),
                },
              }}
            />
          </Grid>
          <Grid xs={5}>
            <Input
              type="date"
              onChange={(e) => {
                console.log(e.target.value);
                setNewToAvailability(moment(e.target.value, "YYYY-MM-DD"));
              }}
              value={moment(newToAvailability).format("YYYY-MM-DD")}
              slotProps={{
                input: {
                  min: moment(newFromAvailability).format("YYYY-MM-DD"),
                },
              }}
            />
          </Grid>
          <Grid xs={2}>
            <Button fullWidth onClick={() => addAvailabilityItem()}>
              Add
            </Button>
          </Grid>
          <Grid xs={12}>
            {isAvailabilityDataLoading || isAvailabilityDataFetching ? (
              <LinearProgress />
            ) : availabilityData?.results?.length > 0 ? (
              availabilityData.results.map((a, idx) => (
                <AvailabilityRangeComponent {...a} key={idx} />
              ))
            ) : (
              <Alert startDecorator={<QuestionMark />}>
                No availability has been created yet for this listing, event and
                accommodation combination. Please create a new availability
                range below.
              </Alert>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
