import { Refresh } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Link, Typography } from "@mui/joy";
import { DateTime } from "luxon";
import OrdersTable from "pages/Dash/Components/OrdersTable/OrdersTable";
import WizardSelectModal from "pages/Dash/Components/WizardSelectModal/WizardSelectModal";
import { useState } from "react";
// import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetOrdersQuery } from "services/osare";

export default function DashHome() {
  const { refetch: refetchOrders } = useGetOrdersQuery();
  const [isWizardOpen, setIsListingWizardOpen] = useState(false);
  const navigate = useNavigate();
  // const { t } = useTranslation();

  const timeOfDay = () => {
    const currentHour = DateTime.local().hour;

    if (currentHour >= 0 && currentHour < 12) {
      return "morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  };

  const timeOfDayEmoji = () => {
    const currentHour = DateTime.local().hour;

    if (currentHour >= 0 && currentHour < 12) {
      return "🌞";
    } else if (currentHour >= 12 && currentHour < 20) {
      return "👋";
    } else {
      return "🌙";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography level="h1" marginTop={3} textAlign={"center"}>
          {timeOfDayEmoji()} Good {timeOfDay()}
        </Typography>
      </Grid>
      <Grid xs={0} sm={3} lg={4}></Grid>
      <Grid xs={12} sm={6} lg={4}>
        <Button
          onClick={() => {
            // setIsListingWizardOpen(true)
            navigate("/dash/wizard");
          }}
          size="lg"
          style={{
            border: "1px solid blue",
            background: "linear-gradient(to right bottom, #536DFE, #6A3DE8)",
          }}
          fullWidth
        >
          ✨ Create a retreat
        </Button>
        <Box marginTop={2} textAlign={"center"}>
          <Chip onClick={() => setIsListingWizardOpen(true)} color="primary">
            View previous retreats
          </Chip>
        </Box>
        <WizardSelectModal
          isOpen={isWizardOpen}
          setIsListingWizardOpen={setIsListingWizardOpen}
        />
      </Grid>
      <Grid xs={0} sm={3} lg={4}></Grid>
      {/* <Grid xs={12}>
        <Typography gutterBottom level="title-lg">
          {t("dashboard.home.notifications.title")}
        </Typography>
        <Alert>{t("dashboard.home.notifications.empty")}</Alert>
      </Grid> */}
      {/* <Grid xs={12}>
        <Typography gutterBottom level="title-lg">
          Balances
        </Typography>
        <BalanceViewer />
      </Grid> */}
      <Grid xs={12}>
        <Typography gutterBottom level="title-lg">
          Latest Orders{" "}
          <Link
            startDecorator={<Refresh />}
            onClick={() => refetchOrders()}
          ></Link>
        </Typography>
        <OrdersTable />
      </Grid>
    </Grid>
  );
}
