/* eslint-disable no-unused-vars */
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { Clock, Location } from "iconsax-react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useGetWizardsQuery } from "services/osare";

export default function WizardSelectModal({ isOpen, setIsListingWizardOpen }) {
  const { data: wizardsData, isLoading, isFetching } = useGetWizardsQuery({});
  const navigate = useNavigate();

  return (
    <Modal open={isOpen} onClose={() => setIsListingWizardOpen(false)}>
      <ModalDialog
        sx={(theme) => ({
          [theme.breakpoints.only("xs")]: {
            top: "unset",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "unset",
          },
        })}
      >
        <ModalClose />

        {isLoading || isFetching ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              overflow: "scroll",
              mx: "calc(-1 * var(--ModalDialog-padding))",
              px: "var(--ModalDialog-padding)",
            }}
          >
            <DialogTitle>Select a Retreat</DialogTitle>
            <DialogContent>
              <List>
                {wizardsData?.results?.map((wizard) => (
                  <ListItem key={wizard._id}>
                    <ListItemButton
                      onClick={() => navigate(`/dash/wizard/${wizard._id}`)}
                    >
                      <ListItemContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography level="body-md">
                              {wizard.listingTitle ?? "(no title)"}
                            </Typography>
                            <Typography
                              marginLeft={0.5}
                              level="body-xs"
                              startDecorator={<Location size={16} />}
                            >
                              {wizard.accommodationTitle ??
                                "(no accommodation name yet)"}
                            </Typography>

                            <Typography
                              marginLeft={0.5}
                              level="body-xs"
                              startDecorator={<Clock size={16} />}
                            >
                              Created:{" "}
                              {wizard.createdAt
                                ? DateTime.fromSeconds(
                                    wizard.createdAt / 1000
                                  ).toLocaleString(
                                    DateTime.DATETIME_MED_WITH_WEEKDAY
                                  )
                                : "(no date)"}
                            </Typography>

                            {wizard.updatedAt && (
                              <Typography
                                marginLeft={0.5}
                                level="body-xs"
                                startDecorator={<Clock size={16} />}
                              >
                                Last updated:{" "}
                                {DateTime.fromSeconds(
                                  wizard.updatedAt / 1000
                                ).toLocaleString(
                                  DateTime.DATETIME_MED_WITH_WEEKDAY
                                )}{" "}
                                (around{" "}
                                {DateTime.fromSeconds(
                                  wizard.updatedAt / 1000
                                ).toRelative()}
                                )
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </ListItemContent>
                      <KeyboardArrowRight />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
}
