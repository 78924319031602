/* eslint-disable no-unused-vars */
import { CalendarMonthTwoTone, FiberManualRecord } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  Input,
  Link,
  Table,
  Typography,
} from "@mui/joy";
import Fuse from "fuse.js";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetListingsQuery, useGetOrdersQuery } from "services/osare";
let fuse = null;

export default function OrdersTable() {
  const {
    data: ordersData,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetOrdersQuery({});
  const { data: listingData, isFetching: isListingDataFetching } =
    useGetListingsQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      console.log("setting new fuse");
      fuse = new Fuse(ordersData, { keys: ["_id", "shortId", "name"] });
    }
  }, [ordersData]);

  const navigate = useNavigate();

  const getOrderColor = (order) => {
    if (order?.scheduledPayments || order?.scheduledPayments?.length === 0) {
      return "warning";
    } else if (order.isAwaitingApproval) {
      return "warning";
    } else {
      return order.isCancelled ? "danger" : "primary";
    }
  };

  // const StatusToColor = (order) => {
  //   if (order.isCancelled) {
  //     return "danger";
  //   } else {
  //     if (order.isAwaitingApproval) {
  //       return "warning";
  //     } else {
  //       return "success";
  //     }
  //   }
  // };

  const StatusToText = (order) => {
    if (order.isCancelled) {
      return `This order has been ${order.isDenied ? "denied" : "cancelled"} ${
        order.isCancelledByMerchant ? "by you" : "by the customer"
      }`;
    } else {
      if (order.isAwaitingApproval) {
        return "This order is waiting for your approval";
      } else {
        return "This order has been received";
      }
    }
  };

  const OrderTableRow = (orderData) => {
    return (
      <tr>
        <td style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 10 }}>
          <Card variant="outlined" color={getOrderColor(orderData)}>
            <Typography
              level="body-sm"
              startDecorator={<CalendarMonthTwoTone />}
            >
              Ordered {DateTime.fromMillis(orderData.createdAt).toRelative()} (
              {orderData.createdAt
                ? DateTime.fromMillis(orderData.createdAt).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                  )
                : "(no date)"}
              )
            </Typography>
            <Typography
              color={getOrderColor(orderData)}
              level="body-sm"
              startDecorator={<FiberManualRecord />}
            >
              {StatusToText(orderData)}
            </Typography>
            <Typography mt={-1} ml={4} color="neutral" level="body-xs">
              Order ID: {orderData._id} / {orderData.shortId}
            </Typography>
            <Typography level="title-md">
              <Link
                onClick={() =>
                  navigate(`/dash/orders/single?id=${orderData._id}`, {
                    state: orderData,
                  })
                }
              >
                {isListingDataFetching
                  ? "Loading..."
                  : "View order for " +
                    listingData.results.find(
                      (l) => l._id === orderData.listingId
                    )?.name}
              </Link>
            </Typography>
          </Card>
          {/*  */}
        </td>
      </tr>
    );
  };

  return (
    <>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Input
            placeholder="Search by Order ID / Booking ID"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (!fuse) {
                console.log("no fuse");
                return;
              }
              const searchResults = fuse.search(e.target.value);
              console.log(searchResults);
              setSearchResults(
                searchResults.length === 0
                  ? ordersData
                  : searchResults.map((r) => r.item)
              );
            }}
          />
          <Table stickyHeader>
            <thead>
              <tr>
                <th style={{ width: "80%" }}>
                  {searchResults.length === 0
                    ? ordersData.length
                    : searchResults.length}{" "}
                  Orders
                </th>
              </tr>
            </thead>
            <tbody>
              {searchResults.length === 0
                ? ordersData.map((o) => <OrderTableRow key={o._id} {...o} />)
                : searchResults.map((o) => (
                    <OrderTableRow key={o._id} {...o} />
                  ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
