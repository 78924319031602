/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";
import { useInViewport } from "react-in-viewport";

import {
  AssignmentOutlined,
  Cancel,
  Check,
  ContentPasteSearch,
  Edit,
  OpenInBrowser,
  Warning,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  Link,
  Typography,
} from "@mui/joy";
import { ArrowRight, Calendar, CalendarRemove, Location } from "iconsax-react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetListingByIdQuery,
  useLazyGetOrdersQuery,
} from "services/osare";

export default function Listings() {
  const urlParams = useParams();
  console.log(urlParams);
  const { data: wizardsData } = useGetWizardsQuery({
    query: {
      listingId: urlParams.listingId,
    },
  });
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  const getOrderColor = (order) => {
    if (order.scheduledPayments.length === 0) {
      return "warning";
    } else {
      return order.isCancelled ? "danger" : "success";
    }
  };

  const OrderBlock = ({ wizardData }) => {
    const myRef = useRef(null);
    const { inViewport } = useInViewport(myRef);
    // eslint-disable-next-line no-unused-vars
    const [
      triggerListingData,
      { data: hydratedListingData, isLoading: isListingDataLoading },
    ] = useLazyGetListingByIdQuery();

    useEffect(() => {
      if (inViewport) {
        triggerListingData(
          {
            id: wizardData.listingId,
            hydrate: true,
          },
          true
        );
      }
    }, [inViewport]);

    const OrderLoading = () => {
      return <Typography>Loading...</Typography>;
    };

    const AvailabilityListItem = ({ date, listingData }) => {
      const [
        triggerOrderData,
        { data: orderData, isSuccess: orderDataSuccess },
      ] = useLazyGetOrdersQuery();
      const navigate = useNavigate();

      useEffect(() => {
        if (!orderData) {
          triggerOrderData(
            {
              listingId: listingData._id,
              availabilityId: date._id,
              resolveUser: true,
              includePayments: true,
            },
            true
          );
        }
      }, []);

      const NonAbandonedOrderStatuses = ({ order }) => {
        return (
          <>
            {order.isCancelled ? (
              <Typography
                marginLeft={4.5}
                level="body-sm"
                startDecorator={<Cancel fontSize={"small"} />}
                color="danger"
              >
                Cancelled
              </Typography>
            ) : order.isAwaitingApproval ? (
              <Typography
                marginLeft={4.5}
                level="body-sm"
                startDecorator={<Warning fontSize={"small"} />}
                color="warning"
              >
                Awaiting approval
              </Typography>
            ) : (
              <Typography
                marginLeft={4.5}
                level="body-sm"
                startDecorator={<Check fontSize={"small"} />}
                color="success"
              >
                Confirmed
              </Typography>
            )}
          </>
        );
      };

      return (
        <Accordion>
          <AccordionSummary>
            <Typography startDecorator={<Calendar />}>
              {DateTime.fromMillis(date.fromTimestamp).toLocaleString(
                DateTime.DATE_SHORT
              )}
              <ArrowRight style={{ marginLeft: 4, marginRight: 4 }} size={12} />
              {DateTime.fromMillis(date.toTimestamp).toLocaleString(
                DateTime.DATE_SHORT
              )}{" "}
              - {orderData ? orderData.length : "-"} orders
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {!orderData ||
                (orderData.length === 0 && (
                  <Typography p={2}>No orders, yet.</Typography>
                ))}

              <Grid mt={1} xs={12}>
                <ButtonGroup variant="soft" size="sm">
                  <Button
                    startDecorator={<ContentPasteSearch fontSize="small" />}
                  >
                    Download all orders
                  </Button>
                </ButtonGroup>
              </Grid>

              {orderData?.map((order) => (
                <Grid key={order._id} xs={12} sm={6} md={4}>
                  <Card color={getOrderColor(order)}>
                    <Box>
                      <Typography
                        color="neutral"
                        startDecorator={
                          <Avatar size="sm" src={`${order.user.photoURL}`} />
                        }
                      >
                        {order.user.displayName}
                      </Typography>

                      {order.scheduledPayments.length > 0 ? (
                        <NonAbandonedOrderStatuses order={order} />
                      ) : (
                        <Typography
                          color="warning"
                          marginLeft={4.5}
                          level="body-sm"
                          startDecorator={<Warning fontSize={"small"} />}
                        >
                          Abandoned
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography level="body-sm">
                        Booked{" "}
                        {DateTime.fromMillis(order.createdAt).toRelative()} (
                        {DateTime.fromMillis(order.createdAt).toLocaleString(
                          DateTime.DATETIME_SHORT
                        )}
                        )
                      </Typography>
                    </Box>
                    <Box>
                      {order.scheduledPayments.length === 0 && (
                        <Typography level="body-sm">
                          No payments taken and no inventory used.
                        </Typography>
                      )}
                      {order.scheduledPayments.map((payment) => (
                        <Typography key={payment._id} level="body-sm">
                          {payment.purpose === "platformAndDepositFee"
                            ? "Deposit:"
                            : "Remaining:"}{" "}
                          {payment.status}
                        </Typography>
                      ))}
                    </Box>
                    <Box>
                      <Typography
                        startDecorator={<AssignmentOutlined />}
                        level="body-sm"
                      >
                        <Link
                          onClick={() =>
                            navigate(`/dash/orders/single?id=${order._id}`)
                          }
                        >
                          View booking details
                        </Link>
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    };

    const OrderInformation = ({ listingData }) => {
      const navigate = useNavigate();
      if (!listingData) {
        return <Typography>No listing data</Typography>;
      }

      return (
        <>
          {listingData.accommodationData[0]?.location?.formattedAddress ? (
            <Typography
              level="body-sm"
              startDecorator={<Location size={20} />}
              mt={-1.5}
            >
              {listingData.accommodationData[0]?.location?.formattedAddress}
            </Typography>
          ) : null}

          <Box>
            <ButtonGroup variant="soft" size="sm">
              <Button
                onClick={() =>
                  window.open(
                    `${configData.sites.bookings.url}/listing?listingId=${wizardData.listingId}`,
                    "_blank"
                  )
                }
                startDecorator={<OpenInBrowser fontSize="small" />}
              >
                View
              </Button>
              <Button
                onClick={() => navigate(`/dash/wizard/${wizardData._id}`)}
                startDecorator={<Edit fontSize="small" />}
              >
                Edit
              </Button>
              <Button startDecorator={<ContentPasteSearch fontSize="small" />}>
                Download all availability
              </Button>
            </ButtonGroup>
          </Box>

          {listingData.availabilityData.length > 0 ? (
            <AccordionGroup>
              {listingData.availabilityData?.map((date) => (
                <AvailabilityListItem
                  key={date._id}
                  listingData={listingData}
                  date={date}
                />
              ))}
            </AccordionGroup>
          ) : (
            <Typography
              color="warning"
              level="body-sm"
              startDecorator={<CalendarRemove size={20} />}
            >
              No availability, yet.{" "}
              <Link marginLeft={1}> Create new availability?</Link>
            </Typography>
          )}
        </>
      );
    };

    return (
      <Card sx={{ mb: 3 }} ref={myRef} key={wizardData._id} level="body-sm">
        <Typography level="body-lg">
          {wizardData.listingTitle ?? "No name"}
        </Typography>

        {isListingDataLoading ? (
          <OrderLoading />
        ) : (
          <OrderInformation listingData={hydratedListingData?.results[0]} />
        )}
      </Card>
    );
  };

  return (
    <Box>
      <Typography
        marginTop={5}
        marginBottom={2}
        level="h3"
        textAlign={"center"}
      >
        Listings
      </Typography>
      <Grid container>
        <Grid xs={0} sm={2}></Grid>
        <Grid xs={12} sm={8}>
          {wizardsData?.results.length === 0 ? (
            <Typography level="body-sm" textAlign={"center"}>
              You have no listings yet. Create one now!
            </Typography>
          ) : (
            wizardsData?.results.map((wizard) => (
              <OrderBlock key={wizard._id} wizardData={wizard} />
            ))
          )}
        </Grid>
        <Grid xs={0} sm={2}></Grid>
      </Grid>
    </Box>
  );
}
